import { ExtraProduct } from "libs/api/customer/src/lib/types/products";

export enum EMenuState {
  OPEN,
  CLOSED,
}

export enum EMenuItemType {
  ACTION,
  LINK,
}

export enum EMenuItemStyle {
  DEFAULT,
  BASIC,
}

interface IMenuItem {
  type: EMenuItemType;
  title: string;
  style?: EMenuItemStyle;
  description?: string;
}
export interface IMenuItemAction extends IMenuItem {
  type: EMenuItemType.ACTION;
  action(): Promise<void> | void;
}

export interface IPopOverMenuOptions {
  [EMenu.MANAGE_FRESH_PLAN]: {
    params: {
      catName: string,
      catId: string,
      subscriptionId: number,
      /** This indicates if the user is eligible to be in the pre-cancellation discount experiment test group. */
      eligible: boolean,
    }
  },
  [EMenu.MANAGE_SCOOP_HEALTH_PLAN]: {
    params: {
      subscriptionId: string,
      subscription: string,
      addonPlanType: ExtraProduct;
    }
  },
  [EMenu.MANAGE_SCOOP_PLANET_PLAN]: {
    params: {
      subscriptionId: string,
      subscription: string,
      addonPlanType: ExtraProduct;
    }
  }
}

export interface IMenuItemLink<T extends EMenu> extends IMenuItem { // eslint-disable-line
  type: EMenuItemType.LINK;
  url: string;
  options?: ISearchParams;
}

export function isMenuItemAction(
  menuItem: IMenuItem,
): menuItem is IMenuItemAction {
  return menuItem.type === EMenuItemType.ACTION;
}

export function isMenuItemLink<T extends EMenu>(menuItem: IMenuItem): menuItem is IMenuItemLink<T> {
  return menuItem.type === EMenuItemType.LINK;
}

export type TMenuItemResource<T extends EMenu> = IMenuItemAction | IMenuItemLink<T>;
export type TMenuItemCollection<T extends EMenu> = readonly TMenuItemResource<T>[];

export interface IMenuDetails {
  state: EMenuState;
  title: string;
  items: TMenuItemCollection<EMenu>;
}

export interface IPopoverMenuStore {
  state: EMenuState;
  setState(state: EMenuState): void;
  title: string;
  setTitle(title: string): void;
  items: TMenuItemCollection<EMenu>;
  setItems(menuItems: TMenuItemCollection<EMenu>): void;
  setMenuDetails(menuDetails: IMenuDetails): void;
  getMenuDetails(): IMenuDetails;
  resetMenuDetails(delay?: number): void;
}

export interface IMenu<T extends EMenu> {
  title: string;
  items: TMenuItemCollection<T>;
}

export enum EMenu {
  MANAGE_FRESH_PLAN,
  MANAGE_SCOOP_HEALTH_PLAN,
  MANAGE_SCOOP_PLANET_PLAN,
}

export type TMenuMap = Record<EMenu, IMenu<EMenu>>;

export interface IMenuParams {
  subscriptionId: string | number;
}

export interface ISearchParams {
}

export interface IGetMenuOptions {
  params?: IMenuParams;
  searchParams?: ISearchParams;
}
