import { validateAndSanitiseGBPostcode } from "@./address/validation";

import {
  IAddress,
  IGetAddressResponse,
  TPostcodeCheckResponse,
} from "./address.types";
import { sanitizeGBPostcode } from "./address.utils";

export function Address(): IAddress {

  const postcodeCheck = (
    postcode: string,
    skipOutOfBounds = false,
  ): TPostcodeCheckResponse => validateAndSanitiseGBPostcode(postcode, skipOutOfBounds);

  const fetchAddressFromPostcode = async (postcode: string): Promise<IGetAddressResponse> => {
    const sanitizedPostcode = sanitizeGBPostcode(postcode);
    const response = await fetch(`https://api.getAddress.io/find/${sanitizedPostcode}?api-key=dyvE3JfD-kW7Xapg5VQyiw35217&expand=true&sort=true`, {
      method: "GET",
    });
    if (response.ok) return response.json();
    const body = await response.text();
    throw new Error(`Something went wrong: ${response.status}: ${body}`);
  };

  return {
    fetchAddressFromPostcode,
    postcodeCheck,
  };
}

export default Address;
