export interface IAddress {
  fetchAddressFromPostcode(postcode: string): Promise<IGetAddressResponse>;
  postcodeCheck(postcode: string, skipOutOfBounds?: boolean): TPostcodeCheckResponse;
}

export enum AddressValidationErrorPostcodeType {
  BadPostcodeFormat = "BadPostcodeFormat",
  DoNotDeliverToPostcode = "DoNotDeliverToPostcode",
}

export type TPostcodeCheckResponse = {
  sanitized: string;
  errorType: null;
  message?: undefined;
} | {
  sanitized: null;
  errorType: AddressValidationErrorPostcodeType;
  message: string;
};

export interface IGetAddressAddresses {
  formatted_address: string[];
  building_name: string;
  building_number: string;
  country: string;
  county: string;
  district: string;
  line_1: string;
  line_2: string;
  line_3: string;
  line_4: string;
  locality: string;
  sub_building_name: string;
  sub_building_number: string;
  thoroughfare: string;
  town_or_city: string;
}
export interface IGetAddressResponse {
  postcode: string;
  latitude: number;
  longitude: number;
  addresses: IGetAddressAddresses[];
}

export interface IFormattedAddress {
  formattedAddress?: string[],
  address1: string;
  address2: string;
  city: string;
  country: string;
  postcode: string;
  phone: string;
}
