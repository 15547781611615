import { create } from "zustand";
import { persist } from "zustand/middleware";

export interface FlowDiscount {
  flowId: string;
  discountCode: string;
}
interface IDiscountStore {
  discountCodes: FlowDiscount[];
  setDiscountCode(flowId: string, newDiscountCode: string | undefined): void;
}

export const useDiscountStore = create<IDiscountStore>()(persist((set) => ({
  discountCodes: [],
  setDiscountCode: (flowId: string, newDiscountCode: string) => set((state) => {
    console.log("Setting new value in discount store", { flowId, newDiscountCode });
    if (state.discountCodes.find((dc) => dc.flowId === flowId)) {
      return ({
        discountCodes: state.discountCodes
          .map((dc) => (dc.flowId === flowId ? ({ ...dc, discountCode: newDiscountCode }) : dc)),
      });
    }
    return {
      discountCodes: [ ...state.discountCodes, { flowId, discountCode: newDiscountCode } ],
    };
  }),
}), { name: "discount-store" }));

export default useDiscountStore;
