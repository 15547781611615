import { AddressValidationErrorPostcodeType } from "./address.types";

export const INVALID_POSTCODES = [
  "AB30", "AB31", "AB33", "AB34", "AB35", "AB36", "AB37", "AB38", "AB41", "AB42", "AB43", "AB42", "AB43", "AB44", "AB45", "AB51", "AB52", "AB53", "AB54", "AB55", "AB56", // Aberdeen
  "DD9", "DD10", // Dundee (BRECHIN/MONTROSE)
  "FK17", "FK18", "FK19", "FK20", "FK21", // Falkirk
  "KA27", "KA28", // Kilmarnock (ISLE OF ARRAN/ISLE OF CUMBRAE)
  "PA20", "PA21", "PA22", "PA23", "PA24", "PA25", "PA26", "PA27", "PA28", "PA29", "PA30", "PA31", "PA32", "PA33", "PA34", "PA35", "PA36", "PA37", "PA38", "PA39", "PA40", "PA41", "PA42", "PA43", "PA44", "PA45", "PA46", "PA47", "PA48", "PA49", "PA60", "PA61", "PA62", "PA63", "PA64", "PA65", "PA66", "PA67", "PA68", "PA69", "PA70", "PA71", "PA72", "PA73", "PA74", "PA75", "PA76", "PA77", "PA78", "PA80", "PA81", "PA82", "PA83", "PA84", "PA85", "PA86", "PA87", "PA88", "PA98", // Paisley
  "PH15", "PH16", "PH17", "PH18", "PH19", "PH20", "PH21", "PH22", "PH23", "PH24", "PH25", "PH26", "PH30", "PH31", "PH32", "PH33", "PH34", "PH35", "PH36", "PH37", "PH38", "PH39", "PH40", "PH41", "PH42", "PH43", "PH44", "PH49", "PH50", // Perth
  "TR21", "TR22", "TR23", "TR24", "TR25", // Truro (ISLES OF SCILLY)
];

export const INVALID_AREAS = [
  "BT", // Northern Ireland
  "GY", // Guernsey
  "IM", // Isle of Man
  "ZE", // Lerwick/Shetland Islands
  "KW", // Kirkwall
  "JE", // Jersey
  "IV", // Inverness
  "HS", // Outer Hebrides Islands
];

export const VALID_POSTCODE_FORMAT = /^[A-Z]{1,2}[0-9][A-Z0-9]? [0-9][A-Z]{2}$/;

export const POSTCODE_ERRORS: Record<AddressValidationErrorPostcodeType, string> = {
  BadPostcodeFormat: "Please check the format of your postcode.",
  DoNotDeliverToPostcode: "Sorry, we don't currently deliver to your area.",
};
