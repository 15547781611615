import {
  AddressValidationErrorPostcodeType,
  TPostcodeCheckResponse,
} from "@./address/address.types";
import { sanitizeGBPostcode } from "@./address/address.utils";
import {
  INVALID_AREAS,
  INVALID_POSTCODES,
  POSTCODE_ERRORS,
  VALID_POSTCODE_FORMAT,
} from "@./address/address.constants";

export function validateAndSanitiseGBPostcode(
  postcode: string,
  skipOutOfBounds = false,
): TPostcodeCheckResponse {
  const sanitizedPostcode = sanitizeGBPostcode(postcode);
  if (!VALID_POSTCODE_FORMAT.test(sanitizedPostcode)) return {
    sanitized: null,
    errorType: AddressValidationErrorPostcodeType.BadPostcodeFormat,
    message: POSTCODE_ERRORS.BadPostcodeFormat,
  };
  if (skipOutOfBounds) return {
    sanitized: sanitizedPostcode,
    errorType: null,
  };
  const firstPart = sanitizedPostcode.split(" ")[0];
  if (INVALID_POSTCODES.includes(firstPart)) return {
    sanitized: null,
    errorType: AddressValidationErrorPostcodeType.DoNotDeliverToPostcode,
    message: POSTCODE_ERRORS.DoNotDeliverToPostcode,
  };
  if (INVALID_AREAS.some((area) => firstPart.startsWith(area))) return {
    sanitized: null,
    errorType: AddressValidationErrorPostcodeType.DoNotDeliverToPostcode,
    message: POSTCODE_ERRORS.DoNotDeliverToPostcode,
  };
  return { sanitized: sanitizedPostcode, errorType: null };
}
